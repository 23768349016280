<template>
  <MosaicUiCoreSidebarMenuItem
    label="cabinet-customer.sidebar.dashboard"
    route="/cabinet/employee/dashboard"
    icon="carbon:dashboard"
  />
  <MosaicUiCoreSidebarMenuItem
    label="cabinet-customer.sidebar.catalog"
    route="/cabinet/employee/catalog"
    icon="ph:user-list"
  />
  <MosaicUiCoreSidebarMenuItem
    label="cabinet-customer.sidebar.chat"
    route="/cabinet/chat"
    icon="ion:chatbubbles-outline"
  />
</template>

<script setup lang="ts">
const { t } = useI18n();
</script>

<style scoped>

</style>
