<template>
  <Transition name="fade">
    <div>
      <div class="bg-black opacity-50 absolute top-0 left-0 w-screen h-screen z-30" @click="toggleSidebar"></div>
      <div class="fixed bottom-0 w-full h-[360px] bg-white z-50 rounded-t-xl mobile-menu p-6">
        <div class="mb-8 flex flex-row justify-between">
          <span class="font-bold text-center basis-full flex-1">{{ t('common.menu') }}</span>
          <span class="text-sm font-bold text-gray-400 self-end" @click="toggleSidebar">{{ t('common.hide') }}</span>
        </div>
        <ul class="list-style-none space-y-6">
          <slot />
        </ul>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
const {toggleSidebar} = useCabinetPreferences();
const {t} = useI18n();
</script>

<style>
.mobile-menu li div {
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
}

.mobile-menu li div svg {
  margin: 0 10px 0 0;
}

.mobile-menu li:before {
  visibility: hidden;
}
</style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
