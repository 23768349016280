<template>
  <div class="bg-white static h-screen hidden sm:block">
    <!-- Sidebar backdrop (mobile only) -->
    <div
        class="fixed inset-0 bg-gray-900 bg-opacity-30 z-40 md:hidden md:z-auto transition-opacity duration-200 opacity-0 pointer-events-none"
        :class="{'opacity-100': isSidebarOpened}"
        aria-hidden="true"
    ></div>

    <!-- Sidebar -->
    <div
        id="sidebar"
        class="flex flex-col pb-14 shadow-[0_8px_40px_0px_rgb(0,58,83,0.04),0_1px_16px_0px_rgb(0,58,83,0.08)] rounded-xl m-[32px] z-40 w-24 shrink-0 bg-white p-1 transition-all duration-200 ease-in-out max-h-full overflow-auto"
    >
      <!-- Sidebar header -->
      <div class="flex justify-center mb-5 p-5">
        <!-- Logo -->
        <NuxtLink @click="isSidebarOpened = false" :to="localePath('/')">
          <CoreShortLogo />
        </NuxtLink>
      </div>

      <!-- Links -->
      <div>
        <ul class="space-y-8">
          <slot />
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
const { isSidebarOpened, toggleSidebar } = useCabinetPreferences();
const { t } = useI18n();

const config = useRuntimeConfig();
const localePath = useLocalePath();
</script>

<style scoped>

</style>
