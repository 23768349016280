<template>
  <li class="relative text-center rounded-sm mb-0.5 last:mb-0 hover:text-gray-700 menu-item"
      :class="{'menu-item-active': isActive}"
  >
    <NuxtLink class="block transition duration-150 text-meclee-black hover:text-meclee-blue-700"
              :class="{'text-meclee-blue': isActive}"
              :to="localePath(props.route)"
              @click="isSidebarOpened = false"
    >
      <div class="flex flex-col justify-center items-center">
        <Icon class="shrink-0 h-6 w-6 mb-2" :name="props.icon" size="24" />
        <span class="text-sm duration-200"
              :class="{'font-medium': isActive}"
        >
            {{ t(props.label) }}
          </span>
      </div>
    </NuxtLink>
  </li>
</template>

<script setup lang="ts">
const { t } = useI18n();
const route = useRoute();
const localePath = useLocalePath();
const {isSidebarOpened} = useCabinetPreferences();

interface Props {
  label: string,
  route: string,
  icon: string,
}

const isActive = computed(() => {
  return route.fullPath.match(localePath(props.route).replace('/', '\\/'));
});

const props = defineProps<Props>();
</script>

<style scoped>
.menu-item-active:before {
  content: '';
  position: absolute;
  left: -3px;
  top: 0;
  width: 3px;
  height: 100%;
  border-radius: 0 13px 13px 0;
  @apply bg-meclee-blue;
}

.menu-item:hover:before {
  content: '';
  position: absolute;
  left: -3px;
  top: 0;
  width: 3px;
  height: 100%;
  border-radius: 0 13px 13px 0;
  @apply bg-meclee-blue-700;
}
</style>
