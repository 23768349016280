<template>
  <MosaicUiCoreSidebarMenuItem
    label="cabinet-hr.sidebar.dashboard"
    :route="`/cabinet/business/dashboard`"
    icon="mingcute:dashboard-3-line"
  />
</template>

<script setup lang="ts">
const { t } = useI18n();
</script>

<style scoped>

</style>
