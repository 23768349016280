import {Profile} from "../../../../../models/session";
import {GetSessionUseCase, SwitchProfileUseCase} from "../../../application";
import {authTypes} from "../../../../../di/types";

const isLoading = ref(false);

export function useMultiProfile() {
  const {$container} = useNuxtApp();
  const localePath = useLocalePath();
  const config = useRuntimeConfig();
  const getSessionUseCase = $container.get<GetSessionUseCase>(authTypes.GetSessionUseCase);
  const switchProfileUseCase: SwitchProfileUseCase = $container.get<SwitchProfileUseCase>(authTypes.SwitchProfileUseCase);

  async function switchProfile(profile: Profile) {
    isLoading.value = true;
    await switchProfileUseCase.execute(profile.id);
    const session = await getSessionUseCase.execute();
    const profileRoute = config.public.auth.homePagesByProfile[session.selectedProfile.type];
    await navigateTo(localePath(profileRoute));
    window.location.reload(true);
    isLoading.value = false;
  }

  return {
    isLoading,
    switchProfile,
  }
}
