import {timeTypes} from "../../../../../di/types";
import {CheckTimeUseCase} from "../../../application/checkTimeUseCase";

export function useCheckTime() {
  const { $container } = useNuxtApp();
  const checkTimeUseCase: CheckTimeUseCase = $container.get<CheckTimeUseCase>(timeTypes.CheckTimeUseCase);

  const isSystemTimeValid = ref(true);
  const error = ref(null);

  async function checkSystemTimeValid() {
    try {
      await checkTimeUseCase.check(Intl.DateTimeFormat().resolvedOptions().timeZone);
    } catch (e) {
      isSystemTimeValid.value = false;
      error.value = e;
    }
  }

  return {
    isSystemTimeValid,
    error,
    checkSystemTimeValid,
  }
}
