const isSidebarOpened = ref(false);

export function useCabinetPreferences() {
  function toggleSidebar() {
    isSidebarOpened.value = !isSidebarOpened.value;
  }

  return {
    isSidebarOpened,
    toggleSidebar,
  }
}
